body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
  background-image: url('./assets/blank-white-waves-aesthetic.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.App {
  text-align: center;
  padding: 20px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  color: darkred;
  margin: 0;
  cursor: pointer;
  font-size: 2em;
}

nav {
  display: flex;
  gap: 10px;
}

nav button, .dashboard-button, .saved-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
}

nav button:hover, .dashboard-button:hover, .saved-button:hover {
  background-color: #0056b3;
}

.logout-button {
  background-color: darkgrey;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  width: auto; 
  display: inline-block; 
}

.logout-button:hover {
  background-color: grey;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.search-bar input {
  width: 600px;  
  padding: 15px;  
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 52px;
  font-size: 1em;
}

.search-bar button {
  margin-left: 10px;
  padding: 10px 20px;  
  height: 35px;  
  width: 112px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px; 
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 0.9em;
}

.search-bar button:hover {
  background-color: #0056b3;
}

.browse-section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.browse-section button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
  width: 160px; 
}

.browse-section button:hover {
  background-color: #0056b3;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  max-width: 350px;
  margin: 20px auto;
  padding: 30px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

input {
  width: 320px;
  padding: 15px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 1em;
}

button:hover {
  background-color: #0056b3;
}

.back-button {
  width: 350px;
  padding: 15px;
  background-color: hsl(0, 0%, 70%);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.text-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 1em;
  width: 350px; 
  text-decoration: none;
  display: inline-block; 
  text-align: center; 
}

.text-button:hover {
  background-color: #0056b3;
  text-decoration: none; 
}

.dashboard {
  padding: 30px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.dashboard-title {
  font-size: 1.7em;
  margin: 0;
  color: black;
}

.item-inputs {
  display: flex;
  gap: 15px;
  margin-top: 30px;
  justify-content: center;
}

.item-inputs input {
  flex: 1;
  font-size: 1em;
}

.inventory {
  margin-top: 30px;
}

.inventory-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ccc;
  font-size: 1.2em;
}

.inventory-item span {
  flex: 1;
}

.inventory-item .item-buttons {
  display: flex;
  gap: 10px;
}

.edit-button {
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1em;
  width: auto;
}

.edit-button:hover {
  background-color: #e0a800;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1em;
  width: auto;
}

.delete-button:hover {
  background-color: #cc0000;
}

.search-results {
  width: 80%;
  margin: 20px auto;
}

.search-result-header,
.search-result-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.search-result-header span,
.search-result-item span {
  flex: 1;
  text-align: center;
}

.search-result-header {
  font-weight: bold;
  background-color: #f0f0f0;
}

.search-result-item span {
  margin-right: 10px;
}

.saved-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;  
  height: 100vh;
  background-image: url('./assets/blank-white-waves-aesthetic.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 10px;  
}

.saved-title {
  color: darkred;
  font-size: 1.5em;
  margin-top: 10px;  
}

.promotions, .shops, .items {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.promotion-list, .shop-list, .item-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.promotion-item-horizontal, .shop-item-horizontal, .item-horizontal {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.promotion-item-horizontal span, .shop-item-horizontal span, .item-horizontal span {
  margin-right: 10px;
  font-size: 1.1em;
}
